@import "custom";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap");

$montserrat: "Montserrat", sans-serif;
$merriweather: "Merriweather", sans-serif;
$open-sans: "Open Sans", sans-serif;

.jumbotron {
  background-color: $secondary !important;
}

.card-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.card-footer .btn {
  margin: 5px;
}

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("./Assets/Topography.png") repeat 0 0;

  background-attachment: fixed;
}

.main {
  min-height: 75.4vh;
  @media only screen and (max-width: 768px) {
    min-height: 30vh;
  }
  overflow: hidden;
  display: block;
  position: relative;
}

.card-img-top {
  width: 100%;
  height: 8em;
  object-fit: cover;
}

.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.deck {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.deck::-webkit-scrollbar {
  display: none;
}

blockquote {
  border: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin: 2.5em auto;
  max-width: 540px;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

blockquote::before {
  color: #dfe7f2;
  content: "“";
  font-family: "georgia";
  font-size: 4em;
  left: 30%;
  pointer-events: none;
  position: absolute;
  top: -0.75em;
}

blockquote::after {
  bottom: -1.2em;
  color: #dfe7f2;
  content: "”";
  font-family: "georgia";
  font-size: 4em;
  pointer-events: none;
  position: absolute;
  right: 30%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $open-sans;
  // border-style: solid;
}
p,
div {
  font-family: $merriweather;
}

.nav-tabs {
  background-color: white;
}

.navbar a:hover svg:not(.compass) {
  -webkit-animation: bounceright 0.3s alternate ease infinite;
  animation: bounceright 0.3s alternate ease infinite;
}

.deck-header {
  width: 100%;
}

.deck-container {
  background-color: $primary;
}

.register {
  background-color: $secondary;
}

.deck:first-child {
  margin-left: auto;
}

.deck:last-child {
  margin-right: auto;
}

.dropdown-menu {
  max-height: 150px;
  overflow-y: scroll;
}

@keyframes bounceright {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(3px);
  }
}

@-webkit-keyframes bounceright {
  from {
    -webkit-transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(3px);
  }
}

.small {
  min-height: 75.4vh;
  @media only screen and (max-width: 768px) {
    min-height: 41vh;
  }
}

.small-mob {
  @media only screen and (max-width: 768px) {
    min-height: 41vh;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.small-mob2 {
  @media only screen and (max-width: 768px) {
    min-height: 48vh;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.select {
  &__option {
    &--is-focused {
      background-color: $primary !important;
    }
  }
  &__control {
    &--is-focused {
      border: solid 3px !important;
      border-color: rgba($secondary, 0.8) !important;
      box-shadow: none !important;
    }
  }

  &__menu-portal {
    max-height: 150px !important;
  }
}

.error {
  li {
    color: $danger;
  }
}

video,
iframe {
  max-width: 100%;
}

.test form-control {
  width: 200px !important;
}

.popover-header {
  background-color: $tertiary;
  color: #fff;
}
.popover-subtitle {
  @extend .popover-header;
  font-size: 0.75rem;
  background-color: #fff;
  border-bottom: 0;
  border-radius: 0;
  color: #000;
}

#tooltip-top > .tooltip-inner {
  background-color: $tertiary;
}

.bs-tooltip-auto[x-placement^="top"] span.arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: $tertiary;
}

.marker2 {
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  z-index: 1;
}
    .secondary-link {
      color: #536878 !important;
    }