@import "../../../App";
@import "../../../custom";

.story img {
  max-width: 100%;
  max-height: 70vh;
  height: auto;
}

.story a{
  color: #536878 !important;
}
.story video {
  max-width: 100%;
  height: auto;
  display: flex;
  margin: 0;
}

.card-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.hero-image {
  height: 60vw;
  max-height: 66vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
}

.preview-modal .hero-text .row {
  h1 {
    font-size: 2vw;
  }

  h2 {
    font-size: 1vw;
  }

  p {
    font-size: max(0.5vw, 15px);
  }
}

.hero-text .row {
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  font-family: $montserrat;

  h1 {
    font-size: 5vw;
  }

  h2 {
    font-size: 2.5vw;
  }

  p {
    font-size: max(1.5vw, 15px);
  }
}

.multiply {
  color: black;
  mix-blend-mode: lighten;
  background-color: rgb($secondary, 0.9);
}

.text {
  text-align: center;
  margin: 0;
  font-weight: 900;
  padding: 25px;
}

// .story {
//   p::first-letter {
//     text-transform: uppercase;
//     font-size: 5em;
//   }
// }
