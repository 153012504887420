.preview-text img {
  max-width: 100%;
  max-height: 30%;
  width: auto;
  height: auto;
}

.preview-modal img {
  max-width: 100%;
  max-height: 30%;
  width: auto;
  height: auto;
}
