blockquote {
  border: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin: 2.5em auto;
  max-width: 540px;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

blockquote::before {
  color: #dfe7f2;
  content: "“";
  background-image: url("../../Assets/Logo.png");
  font-family: "georgia";
  font-size: 8em;
  left: 30%;
  pointer-events: none;
  position: absolute;
  top: -0.75em;
}

blockquote::after {
  bottom: -1.2em;
  color: #dfe7f2;
  content: "”";
  font-family: "georgia";
  font-size: 8em;
  pointer-events: none;
  position: absolute;
  right: 30%;
}
