.close:hover,
#distance-slider {
  cursor: pointer;
}

.sidebar {
  background-color: white;
  width: 40vw;

  @media only screen and (max-width: 768px) {
    min-width: 100vw;
  }
}
