//Monarch of the Glen
$primary: #dfe7f2;
$secondary: #b0c1d9;
$contrast-primary: #bf8c60;
$contrast-secondary: #a6634b;
$tertiary: #534659;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "contrast-primary": $contrast-primary,
  "contrast-secondary": $contrast-secondary,
  "tertiary": $tertiary,
);

@import "~bootstrap/scss/bootstrap";

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @each $size, $length in $sizes {
//     @include media-breakpoint-up($breakpoint) {
//       .w-#{$breakpoint}-#{$size} {
//         width: $length !important;
//       }
//     }
//   }
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @each $size, $height in $sizes {
//     @include media-breakpoint-up($breakpoint) {
//       .h-#{$breakpoint}-#{$size} {
//         height: $height !important;
//       }
//     }
//   }
// }
