@import "../../custom";

.story-card {
  width: 15rem;
  height: 20rem;
}

.deck-container:nth-child(1) {
  margin-top: 0 !important;
}

.explore-container:nth-child(2) {
  margin-top: 0 !important;
}

.no-stories {
  height: 100px;
  width: 100%;
}
