#map-container {
  height: 100%;
  width: 100%;
  min-height: 33vh;

  @media only screen and (max-width: 768px) {
    min-height: 55vh;
  }

  & > div {
    min-height: 33vh;
    @media only screen and (max-width: 768px) {
      min-height: 55vh;
    }
  }
}
