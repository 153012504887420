.not-found {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
    url("../../Assets/NorthernLightsBG.jpeg");
  background-color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 90vh;
  overflow: auto;
  color: white;

  h1 {
    font-size: 10vw;
  }

  a {
    font-size: 2.5vw;
  }
}
