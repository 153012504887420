.lander {
  background-image: url("../../Assets/Rock.jpeg");
}

.about {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;

  height: 90vh;

  img {
    width: 100%;
    height: auto;
  }
}

.abc {
  background-image: linear-gradient(
      rgba(125, 125, 125, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url("../../Assets/Hut.jpeg");
}

.story-types {
  width: 100%;
  height: auto;
}

.parallax {
  @media (min-width: 1025px) {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

#npa-link {
  width: 100%;
  height: auto;
}
